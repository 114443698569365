import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPage } from '../Page';
import ActionCreator from '../ActionCreator';
import PathPrefix from '../Utils/PathPrefixUtil';
import * as L from '../Utils/Lang';
import TimeLine from '../Components/Timelinev7';
import Banner from '../Components/Banner';
import Link from '../Components/Link';
import * as Widget from '../Components/Widget';

class FlowPage extends React.Component {
  render() {
    return (
      <Wrapper>
        <Banner
          image={PathPrefix('/images/pages/flow/flow_banner.png')}
          text="SERVICES"
        />
        <TimeLine />
        <ButtonWrapper>
          <Link to={'/contact'}>
            <Widget.FlatButton
              backgroundColor={'#705577'}
              hoverColor={'#705577'}
              style={{ color: 'white', margin: '0px 50px' }}
              labelStyle={{verticalAlign: 'bottom'}}
              label={L.s('contact')}
            />
          </Link>
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div``;

let ButtonWrapper = styled.div`
  padding: 40px;
  background-color: #efefef;

  width: 100vw;
  display: flex;
  justify-content: center;

`;

export default withPage(
  connect(
    null,
    ActionCreator
  )(FlowPage)
);
