import React from 'react';
import styled from 'styled-components';
import * as Widget from './Widget';
import './FontGothic.css';

const Wrapper = styled.div`
  /* filter: brightness(50%); */
  height: 320px;
  color: white;
  text-shadow: 1px 1px 2px black;
  font-size: 30px;
  letter-spacing: 5px;
  font-family: 'Nanum Gothic', 'Noto Sans TC', sans-serif;
  text-align: center;
  ${props => props.extraCss || ''};
`;

const Banner = ({ extraCss, image, text }) => (
  <Wrapper extraCss={extraCss}>
    <Widget.BgImage
      image={image}
      extraCss="display:flex;justify-content: center; align-items: center;"
    >
      <h1>{text}</h1>
    </Widget.BgImage>
  </Wrapper>
);

export default Banner;
